import React from 'react';

function App() {
  return (
    <>
      <h1>.</h1>
    </>
  );
}

export default App;
